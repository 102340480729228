<template>
  <b-card no-body>
    <b-card-body>
      <b-row>

        <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Start') + $t('Date') }}</label>
          <flat-pickr
            :value="startDateFilter"
            class="form-control"
            :config="configs.start_date"
            :reduce="val => val.value"
            @input="(val) => $emit('update:startDateFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('End') + $t('Date') }}</label>
          <flat-pickr
            :value="endDateFilter"
            class="form-control"
            :config="configs.end_date"
            :reduce="val => val.value"
            @input="(val) => $emit('update:endDateFilter', val)"
          />
        </b-col>

        <b-col
          v-if="$can('audit', 'all')"
          cols="12"
          md="4"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Branch') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="branchFilter"
            :options="branchOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:branchFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Category') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="categoryFilter"
            :options="categoryOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:categoryFilter', val)"
          />
        </b-col>

        <!-- <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Sale Region') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="saleRegionFilter"
            :options="saleRegionOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:saleRegionFilter', val)"
          />
        </b-col> -->

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    startDateFilter: {
      type: [String, null],
      default: null,
    },
    endDateFilter: {
      type: [String, null],
      default: null,
    },
    branchFilter: {
      type: [String, null],
      default: null,
    },
    branchOptions: {
      type: Array,
      required: true,
    },
    categoryFilter: {
      type: [String, null],
      default: null,
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    // saleRegionFilter: {
    //   type: [String, null],
    //   default: null,
    // },
    // saleRegionOptions: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    return {
      configs: {
        start_date: {
          minDate: null,
          maxDate: null,
        },
        end_date: {
          minDate: null,
          maxDate: null,
        },
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
