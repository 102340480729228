<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <div>

      <!-- Filters -->
      <!-- <sale-item-filters
        :start-date-filter.sync="startDateFilter"
        :end-date-filter.sync="endDateFilter"
        :branch-filter.sync="branchFilter"
        :branch-options="branchOptions"
        :category-filter.sync="categoryFilter"
        :category-options="categoryOptions"
        :sale-region-filter.sync="saleRegionFilter"
        :sale-region-options="saleRegionOptions"
      /> -->

      <!-- Filters -->
      <sale-item-filters
        :start-date-filter.sync="startDateFilter"
        :end-date-filter.sync="endDateFilter"
        :branch-filter.sync="branchFilter"
        :branch-options="branchOptions"
        :category-filter.sync="categoryFilter"
        :category-options="categoryOptions"
      />

      <!-- Table Container Card -->
      <b-card
        class="m-0 p-0"
      >
        <b-card-header class="m-0 pt-0 pr-0 pl-0">
          <b-card-title>{{ $t('Sale') + $t('Items') }}</b-card-title>

          <b-dropdown
            variant="link"
            no-caret
            class="chart-dropdown"
            toggle-class="p-0"
            right
            @show="downloadSaleItems"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-body cursor-pointer"
              />
            </template>
            <b-dropdown-item
              href="#"
            >
              <download-csv
                v-if="reportSaleItem"
                :labels="saleItemLabels"
                :data="reportSaleItem"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download</span>
              </download-csv>
            </b-dropdown-item>
          </b-dropdown>
        </b-card-header>

        <b-card-body class="m-0 p-0">
          <b-table
            ref="refSaleItemTable"
            class="position-relative"
            :items="fetchSaleItems"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            stacked="sm"
          >
            <!-- Column: Quantity -->
            <template #cell(total_quantity)="data">
              {{ Number(data.item.total_quantity).toLocaleString() }}
            </template>
            <!-- Column: Price -->
            <template #cell(price)="data">
              {{ Number(data.item.price).toLocaleString() }}
            </template>
            <!-- Column: Amount -->
            <template #cell(total_amount)="data">
              {{ Number(data.item.total_amount).toLocaleString() }}
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-link
                class="btn btn-link btn-icon mr-1"
                @click="showItemDetail(data.item.item_id, data.item.item_name)"
              >
                <feather-icon
                  icon="ActivityIcon"
                  size="16"
                />
              </b-link>
            </template>

          </b-table>
        </b-card-body>

      </b-card>

      <!-- modal backdrop item detail -->
      <b-modal
        id="modal-item-detail"
        ok-only
        no-close-on-backdrop
        :title="modalTitle"
        ok-title="Close"
        size="xl"
      >
        <h5 class="mb-1">{{ startDateFilter + ' ' + $t('To') + ' ' + endDateFilter }} &nbsp; {{ $t('Sale') + $t('Quantity') + ' ' + $t('Total') }} = {{ chartDataTotalQuantity }}
        </h5>

        <apex-line-chart
          :start-date.sync="startDateFilter"
          :end-date.sync="endDateFilter"
          :chart-xaxis.sync="chartXaxis"
          :chart-yaxis.sync="chartYaxis"
          :chart-data.sync="chartData"
        />

      </b-modal>

    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BTable, VBToggle, BCardHeader, BCardTitle, BCardBody, BDropdown, BDropdownItem,
  BLink, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import DownloadCsv from 'vue-json-csv'
// import { useStore } from 'vuex'
import ApexLineChart from '../chart/ApexLineChart.vue'
import SaleItemFilters from './SaleItemFilters.vue'
import useSaleItem from './useSaleItem'
import storeModule from '../../common/storeModule'
import saleItemStoreModule from './saleItemStoreModule'

export default {
  components: {
    SaleItemFilters,
    BOverlay,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTable,
    BDropdown,
    BDropdownItem,
    DownloadCsv,
    BLink,
    BModal,
    ApexLineChart,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      branchOptions: [],
      categoryOptions: [],
      saleRegionOptions: [],
      reportSaleItem: [],
      modalTitle: 'Item Detail',
      chartDataTotalQuantity: 0,
      chartData: {
        name: 'Quantity',
        data: [],
      },
      chartXaxis: {
        labels: {
          trim: false,
          rotate: 0,
          minHeight: 40,
          hideOverlappingLabels: true,
        },
        categories: [
        ],
      },
      chartYaxis: {

      },
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'sale_item'
    // const thisStore = useStore()

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, saleItemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchSaleItems,
      saleItems,
      saleItemLabels,
      refSaleItemTable,
      refetchData,
      tableColumns,
      // Filters
      branchFilter,
      startDateFilter,
      endDateFilter,
      categoryFilter,
      // saleRegionFilter,
      sortBy,
      isSortDirDesc,
    } = useSaleItem()

    return {

      fetchSaleItems,
      saleItems,
      saleItemLabels,
      refSaleItemTable,
      refetchData,
      tableColumns,
      // Extra Filters
      branchFilter,
      startDateFilter,
      endDateFilter,
      categoryFilter,
      // saleRegionFilter,
      sortBy,
      isSortDirDesc,
    }
  },
  mounted() {
    this.loadBranches()
    this.loadCategories()
    // this.loadSaleRegions()
  },
  methods: {
    loadBranches() {
      store
        .dispatch('common/fetchBranches')
        .then(response => {
          const { branches } = response.data.data
          for (let i = 0; i < branches.length; i += 1) {
            this.branchOptions.push({ label: branches[i].name, value: branches[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadCategories() {
      store
        .dispatch('common/fetchCategories')
        .then(response => {
          const { categories } = response.data.data
          for (let i = 0; i < categories.length; i += 1) {
            this.categoryOptions.push({ label: categories[i].name, value: categories[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    // loadSaleRegions() {
    //   store
    //     .dispatch('common/fetchSaleRegions')
    //     .then(response => {
    //       const { sale_regions } = response.data.data
    //       for (let i = 0; i < sale_regions.length; i += 1) {
    //         this.saleRegionOptions.push({ label: sale_regions[i].name, value: sale_regions[i].id.toString() })
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // },
    search() {
      this.refetchData()
    },
    downloadSaleItems() {
      this.reportSaleItem = this.saleItems
    },
    showItemDetail(itemId, itemName) {
      this.modalTitle = itemName
      store
        .dispatch('sale_item/fetchAll', {
          subject: 'detail',
          item_id: itemId,
          start_date: this.startDateFilter,
          end_date: this.endDateFilter,
          branch_id: this.branchFilter,
          category_id: this.categoryFilter,
          // sale_region_id: this.saleRegionFilter,
        })
        .then(response => {
          this.chartData.data = []
          const { sale_items } = response.data.data
          let tqty = 0
          sale_items.forEach(obj => {
            tqty += Number(obj.quantity)
            this.chartData.data.push(obj.quantity)
            this.chartXaxis.categories.push(obj.sale_date)
          })
          this.chartDataTotalQuantity = tqty
          this.$bvModal.show('modal-item-detail')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.modal-dialog {
  max-width: 100% !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
